@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: SharpGrotesk;
  src: url('./fonts/SharpGrotesk.woff');
}


:root {
  --color-main: #511784;
  --color-darkMain: #dd00cb;
  --color-secundary: #ff00ea;
  --color-ternary: #00faff;
  --color-texts: #FFF;
  --color-tyc: #F9CC07;
  --color-textDark: #DD1D21;
  --color-tycText: #555;
  --color-amarillito: #F9CC07;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  color: var(--color-texts);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}


body #root {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
*::-webkit-scrollbar-track {
  background: #0000;
}
*::-webkit-scrollbar-thumb {
  background: var(--color-secundary);
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb:hover {
  background: var(--color-darkMain);
}

.swal2-popup {
  border-radius: 20px !important;
}

.swal2-title {
  padding: 0px !important;
}

.swal2-title br {
  font-size: 0 !important;
}

.swal2-image {
  margin: 3vw auto 1vw auto !important;
}

.closePopUp {
  position: absolute;
  top: 0%;
}

button.swal2-close {
  color: var(--color-texts);
}

/* spin loader */

.spinLoader {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;

  border: 3px solid transparent;
  border-top: 3px solid var(--color-ternary);
  border-left: 3px solid var(--color-ternary);
  border-bottom: 3px solid var(--color-ternary);
  border-radius: 50%;

  margin: 10px auto;

  animation-name: girar;
  animation-duration: .9s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes girar {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
